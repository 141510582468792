<template>

  <v-menu
    v-model="dialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :error-messages="errors"
        v-model="formatDate"
        :class="css"
        :rounded="rounded"
        outlined
        :dense="dense"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        :hide-details ="hide_details"
      ></v-text-field>
    </template>
    <v-date-picker :no-title='no_title' v-model="date" @input="emit()" @change="emit()"></v-date-picker>
  </v-menu>

</template>

<script>
export default {
 props:{
   value:{},
   errors:{},
   label:{type:String},
   css:{type:String},
   no_title:{type:Boolean,default:true},
   rounded:{type:Boolean,default:false},
   dense:{type:Boolean,default:true},
   hide_details:{type:Boolean,default:false}
 },
 data:()=>({
   dialog:false,
   date:'',
 }),
 watch:{
   value(val){
      this.date = val
   }
 },
 created(){
      this.date = this.value
 },
 computed:{
   formatDate() {
      const date = this.date
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
 },
 methods:{
   emit(){
      this.$emit('input',this.date)
      this.$emit('change',this.date)
      this.dialog = false
   },

 }
}
</script>

<style>
</style>
